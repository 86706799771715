import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Link } from 'gatsby';

import '@css/tailwind.css';
import '@css/main.css';

const InstrumentalConfirmation = () => {
  return (
    <AnimatePresence exitBeforeEnter>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1 }}
        className='flex items-center h-full min-h-screen pt-10 sm:pt-0 entry-form-container pb-20'>
        <Link
          to='/'
          className='btn-entry absolute top-11 sm:top-9  xl:top-20  right-gutter  '>
          Go Home
        </Link>
        <div>
          <h2 className='richH0 mb-10'>Kia Ora,</h2>
          <div className='blockH7 space-y-5 pb-7'>
            <p>
              Thank you for your entry form for the 2025 NZCT Chamber Music
              Contest - Instrumental Performance. We are currently working
              through the submissions and will be back in contact shortly but in
              the meantime, here are some key dates:
            </p>

            <ul className=''>
              <li className='list-disc list-inside'>
                Entry fee invoices will be sent out to you in the week
                commencing <span className=' font-bold'>Monday 7 April</span>
              </li>
            </ul>
            <p>
              If you would like to contact us regarding the contest, please
              email{' '}
              <a href='mailto:contest@chambermusic.co.nz' className='underline'>
                contest@chambermusic.co.nz
              </a>{' '}
              or for more information please check out our website{' '}
              <a href='/nzct-contest' className='underline'>
                Contest | CMNZ (chambermusic.co.nz)
              </a>
            </p>
            <p> Ngā mihi,</p>
            <div className='space-y-1'>
              <p> Bleau Bustenera</p>
              <p> Manager of Artistic Delivery & Communities</p>
            </div>
          </div>
          <Link to='/' className='btn-entry '>
            Explore CMNZ{' '}
          </Link>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default InstrumentalConfirmation;
